import Link from '@components/atoms/Link';
import Text from '@components/atoms/Text';
import { Dropdown, Logo } from '@components/website/SiteHeader/NavComponents';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { SUBMENU_PATHS } from '../../../constants';
import styles from './Nav.module.scss';

const getHighlightStyles = (isActive: boolean) => {
  return [
    {
      [styles.active]: isActive
    }
  ];
};

const Nav: React.FC = () => {
  const router = useRouter();
  const [hasDropdown, setHasDropdown] = useState(false);
  const isInSystem = SUBMENU_PATHS.map(path => path.path).some(substring =>
    router.pathname.includes(substring)
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.navBar}>
        <Logo className={styles.logo} />

        <nav className={styles.nav} role="navigation">
          <ul>
            <li
              className={styles.navItem}
              onMouseEnter={() => setHasDropdown(true)}
              onMouseLeave={() => setHasDropdown(false)}
            >
              <Link
                href="/overview"
                aria-haspopup="true"
                aria-label="Go to our overview page"
                className={cx(styles.link, getHighlightStyles(isInSystem))}
                onFocus={() => setHasDropdown(true)}
              >
                <Text variant="navLink" as="span">
                  Meet Pio
                </Text>
              </Link>
              <Dropdown
                className={styles.dropdown}
                isOpen={hasDropdown}
                onBlur={() => setHasDropdown(false)}
              />
            </li>
            <li className={styles.navItem}>
              <Link
                href="/why-pio"
                className={cx(
                  styles.link,
                  getHighlightStyles(router.pathname === '/why-pio')
                )}
                aria-label="Go to why Pio page"
              >
                <Text variant="navLink" as="span">
                  Why Pio?
                </Text>
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link
                href="/pricing"
                className={cx(
                  styles.link,
                  getHighlightStyles(router.pathname === '/pricing')
                )}
                aria-label="Go to pricing page"
              >
                <Text variant="navLink" as="span">
                  Pricing
                </Text>
              </Link>
            </li>
          </ul>

          <ul className={styles.buttonsWrapper}>
            <li className={styles.navItem}>
              <Link
                href="/get-started"
                className={cx(styles.link, styles.bgYellow)}
                aria-label="Go to get started page"
              >
                <Text variant="navLink" as="span">
                  Start now
                </Text>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Nav;
