import Icon from '@components/atoms/Icon';
import Link from '@components/atoms/Link';
import Text from '@components/atoms/Text';
import { Column, Columns } from '@components/website/Columns';
import FormNewsletter from '@components/website/FormNewsletter';
import { BREAKPOINT_TABLET } from '@constants';
import { MotionValue, useInView } from 'framer-motion';
import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useMedia } from 'react-use';

import { SiteFooterType } from '../../../types';
import CurrencyToggle from './CurrencyToggle';
import {
  SITE_FOOTER_DEFAULT_SECTIONS,
  SITE_FOOTER_PRIVACY_PATHS
} from './defaultData';
import styles from './SiteFooter.module.scss';

interface SiteFooterStyle {
  opacity: MotionValue<number>;
}

interface SiteFooterProps {
  style: SiteFooterStyle;
  onViewChange: (isInView: boolean) => void;
  siteFooter?: SiteFooterType;
}

const SiteFooter: React.FC<SiteFooterProps> = ({
  style,
  onViewChange,
  siteFooter
}) => {
  const isNarrow = useMedia(`(max-width: ${BREAKPOINT_TABLET}px)`, false);
  const ref = useRef(null);
  const isInView = useInView(ref);

  const siteFooterSections =
    siteFooter?.sections ?? SITE_FOOTER_DEFAULT_SECTIONS;
  const siteFooterPrivacyPaths =
    siteFooter?.privacyPaths ?? SITE_FOOTER_PRIVACY_PATHS;

  useEffect(() => {
    onViewChange(isInView);
  }, [onViewChange, isInView]);

  return (
    <motion.footer style={style} ref={ref} className={styles.wrap}>
      <Columns className={styles.columns}>
        <Column start={1} span={6} className={styles.column}>
          <Icon name="pio" fill="grey" size="none" className={styles.logo} />

          <FormNewsletter />
        </Column>

        <Column start={isNarrow ? 1 : 7} span={6} className={styles.column}>
          <nav className={styles.mainNav}>
            {siteFooterSections.map(({ sectionName, links }) => (
              <div key={sectionName} className={styles.navColumns}>
                <Text className={styles.navCategory} as="span" variant="label">
                  {sectionName}
                </Text>

                <ul>
                  {links.map(({ label, href }) => (
                    <li key={href}>
                      <Link href={href} className={styles.link}>
                        <Text as="span" variant="b2">
                          {label}
                        </Text>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div key={'currency'} className={styles.navColumns}>
              <Text className={styles.navCategory} as="span" variant="label">
                Currency
              </Text>
              <ul>
                <li key="currencyToggle">
                  <CurrencyToggle />
                </li>
              </ul>
            </div>
          </nav>
        </Column>

        <Column start={1} span={12} className={styles.column}>
          <nav className={styles.privacyNav}>
            <ul>
              {siteFooterPrivacyPaths.map(({ label, href }) => (
                <li key={`footerNav-${href}`}>
                  <Link href={href} className={styles.link}>
                    <Text as="span" variant="b3">
                      {label}
                    </Text>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <a
            href="https://www.autostoresystem.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Icon name="createdByAutostore" size="none" fill="navy" />
          </a>
        </Column>
      </Columns>
    </motion.footer>
  );
};

export default SiteFooter;
