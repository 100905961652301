import { getMotionValue } from '@helpers/animations';
import useLerp from '@hooks/useLerp';
import useRaf from '@hooks/useRaf';
import useWindowResize from '@hooks/useWindowResize';
import { Seo } from '@sanity/sanity.types';
import { SiteFooterType } from '@types';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { useRef } from 'react';

import SeoMeta from '../SeoMeta';
import SiteFooter from '../SiteFooter';
import SiteHeader from '../SiteHeader';
import styles from './styles.module.scss';

const transformRange = [1, 0.95];
const opacityRange = [0, 1];
const startOffset = 200;
const scrollAmount = 200;

const SiteLayout: React.FC<{
  isFooterHidden?: boolean;
  seoMeta?: Seo;
  siteFooter?: SiteFooterType;
  children?: React.ReactNode;
}> = ({ isFooterHidden, seoMeta, siteFooter, children }) => {
  const mainRef = useRef<HTMLElement>(null);

  const scrollRange = useRef([0, 0]);
  const scale = useMotionValue(1);
  const opacity = useTransform(scale, transformRange, opacityRange);

  const getLerpValue = useLerp();

  const [loopStop, loopStart] = useRaf(scrollTop => {
    const value = getMotionValue(
      scrollRange.current,
      transformRange,
      scrollTop
    );
    const lerpedValue = getLerpValue(value);

    scale.set(lerpedValue);
  });

  useWindowResize(() => {
    const mainBottom =
      (mainRef.current?.offsetHeight ?? 0) - window.innerHeight;

    scrollRange.current = [
      mainBottom + startOffset,
      mainBottom + startOffset + scrollAmount
    ];
  });

  const handleFooterViewChange = (inView: boolean) => {
    if (inView) loopStart();
    else {
      scale.set(1);
      loopStop();
    }
  };

  return (
    <>
      {seoMeta ? <SeoMeta {...seoMeta} /> : <SeoMeta />}
      <SiteHeader />
      <motion.main ref={mainRef} style={{ scale }}>
        {children}
        <motion.div className={styles.overlay} />
      </motion.main>

      {!isFooterHidden && (
        <SiteFooter
          style={{ opacity }}
          onViewChange={handleFooterViewChange}
          siteFooter={siteFooter}
        />
      )}
    </>
  );
};

export default SiteLayout;
