import Link from '@components/atoms/Link';
import Text from '@components/atoms/Text';
import { CheckboxField, InputField } from '@components/inputs';
import * as Sentry from '@sentry/nextjs';
import cx from 'classnames';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import * as gtm from '../../../helpers/gtm';
import HubspotAPI from '../../../hubspot/api';
import { emailSchema } from '../../../validation/coreValidator';
import Button from '../../Button';
import styles from './FormNewsletter.module.scss';

const getSubtextComponent = (isError: boolean, isSuccess: boolean) => (
  <Text as="span" variant="b3" color="grey5">
    {!isSuccess && !isError && (
      <>
        By signing up for our newsletter you agree to our{' '}
        <Link className={styles.privacyLink} href="/privacy-policy">
          privacy policy
        </Link>
      </>
    )}
    {isSuccess && !isError && <>Thank you for signing up!</>}
    {!isSuccess && isError && (
      <>Sorry! Something went wrong... please try again.</>
    )}
  </Text>
);

const FormNewsletter: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const subtextComponent = getSubtextComponent(isError, isSuccess);

  return (
    <>
      <div
        className={cx(styles.wrap, {
          [styles.formDisabled]: isSuccess
        })}
      >
        <Formik
          initialValues={{
            companyEmail: '',
            isNewsletterSubscribed: true
          }}
          validationSchema={Yup.object({
            companyEmail: emailSchema,
            isNewsletterSubscribed: Yup.boolean()
          })}
          onSubmit={async (values, formikHelpers) => {
            try {
              setIsError(false);
              gtm.newsletterSubmitted();
              await HubspotAPI.updateCustomerData(values.companyEmail, values);
              setIsSuccess(true);
            } catch (error) {
              Sentry.captureException(error);
              setIsError(true);
            }
            formikHelpers.setSubmitting(false);
          }}
        >
          {formik => (
            <Form id="newsletter_form" className={styles.formWrap}>
              <Text className={styles.navCategory} as="span" variant="label">
                Join the list
              </Text>

              <div className={styles.inputWrapper}>
                <CheckboxField
                  className={styles.hidden}
                  label="Subscribe to Pio newsletter"
                  name="isNewsletterSubscribed"
                  value={true}
                />

                <InputField
                  type="email"
                  name="companyEmail"
                  placeholder="Company email"
                  showErrorIfExists
                  submitButton={
                    <Button
                      variant="iconOnlyMain"
                      type="submit"
                      isLoading={formik.isSubmitting}
                      disabled={formik.isSubmitting || !formik.isValid}
                    />
                  }
                />
              </div>
              {subtextComponent}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default FormNewsletter;
