export const SITE_FOOTER_DEFAULT_SECTIONS = [
  {
    sectionName: 'Product',
    links: [
      {
        label: 'Why Pio?',
        href: '/why-pio'
      },
      {
        label: 'Get started',
        href: '/get-started'
      },
      {
        label: 'Pricing',
        href: '/pricing'
      }
    ]
  },
  {
    sectionName: 'Pio System',
    links: [
      {
        label: 'Overview',
        href: '/overview'
      },
      {
        label: 'Components',
        href: '/components'
      },
      {
        label: 'Installation',
        href: '/installation'
      },
      {
        label: 'Integration',
        href: '/integration'
      }
    ]
  },
  {
    sectionName: 'Company',
    links: [
      {
        label: 'Our story',
        href: '/our-story'
      },
      {
        label: 'Careers',
        href: '/careers'
      },
      {
        label: 'PR & Media',
        href: '/press'
      }
    ]
  },
  {
    sectionName: 'Follow Us',
    links: [
      {
        label: 'LinkedIn',
        href: 'https://www.linkedin.com/company/pioautomation'
      },
      {
        label: 'Instagram',
        href: 'https://www.instagram.com/pioautomation'
      },
      {
        label: 'Facebook',
        href: 'https://www.facebook.com/Pio-115187180384350/'
      }
    ]
  },
  {
    sectionName: 'Support',
    links: [
      {
        label: 'Contact us',
        href: '/contact'
      },
      {
        label: 'FAQ',
        href: '/faq'
      }
    ]
  }
];

export const SITE_FOOTER_PRIVACY_PATHS = [
  {
    label: 'Terms and Conditions',
    href: '/terms-and-conditions'
  },
  {
    label: 'Privacy Policy',
    href: '/privacy-policy'
  },
  {
    label: 'Cookies Policy',
    href: '/cookies-policy'
  }
];
