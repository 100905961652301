import { diff, lerp, round } from '@helpers/animations';
import { useRef } from 'react';

export default function useLerp(amount = 0.15): (target: number) => number {
  const prevValue = useRef<number>();

  const getLerpValue = (target: number) => {
    const newValue = lerp(prevValue.current ?? target, target, amount);
    prevValue.current = newValue;

    const difference = diff(target, newValue);
    return difference < 0.0005 ? target : round(newValue, 10000);
  };

  return getLerpValue;
}
