import { useEffect, useState } from 'react';
import { useEvent } from 'react-use';

const useWindowResize = (cb: () => void) => {
  const [isMounted, setMounted] = useState(false);

  // Call callback on initialization and call again after first render, as some elements are depended on the window
  useEffect(() => setMounted(true), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(cb, [isMounted]);

  // Call callback on resize
  useEvent('resize', cb);
};

export default useWindowResize;
