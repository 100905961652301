import { getScrollPos } from '@helpers/animations';
import { useRef } from 'react';
import { useEvent, useRafLoop } from 'react-use';

export default function useRaf(cb: (scrollTop: number) => void) {
  const scrollTop = useRef(0);

  const [loopStop, loopStart, isActive] = useRafLoop(() =>
    cb(scrollTop.current)
  );

  const onScroll = () => {
    if (!isActive()) return;

    const scrollPos = getScrollPos();
    scrollTop.current = scrollPos.scrollTop;
  };
  useEvent('scroll', onScroll);

  return [loopStop, loopStart];
}
