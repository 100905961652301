import Link from '@components/atoms/Link';
import Text, { TextProps } from '@components/atoms/Text';
import { SUBMENU_PATHS } from '@constants';
import cx from 'classnames';
import { motion } from 'framer-motion';
import PioLogo from 'public/static/images/pio-logo.svg';
import { FocusEventHandler } from 'react';

import styles from './NavComponents.module.scss';

export const Logo: React.FC<{ className?: string }> = ({ className }) => (
  <Link href="/" aria-label="Pio.com Homepage">
    <div className={className}>
      <PioLogo />
    </div>
  </Link>
);

const dropdownTransition = {
  type: 'easeIn',
  duration: 0.3
};

export const Dropdown: React.FC<{
  className?: string;
  isOpen: boolean;
  color?: TextProps['color'];
  onBlur?: () => void;
}> = ({ className, isOpen, color, onBlur }) => {
  const handleOnBlur: FocusEventHandler<HTMLDivElement> = e => {
    if (!e.currentTarget.contains(e.relatedTarget as Node) && onBlur) {
      onBlur();
    }
  };

  return (
    <motion.div
      className={cx(styles.dropdown, className)}
      initial={{ height: 0 }}
      animate={{ height: isOpen ? 'auto' : 0 }}
      transition={dropdownTransition}
      onBlur={handleOnBlur}
    >
      <motion.ul
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={dropdownTransition}
        aria-label="submenu"
      >
        {SUBMENU_PATHS.map(({ label, path }) => (
          <li key={label}>
            <Link href={path}>
              <Text color={color} variant="navLink" as="span">
                {label}
              </Text>
            </Link>
          </li>
        ))}
      </motion.ul>
    </motion.div>
  );
};

export const Hamburger: React.FC<{
  showMenu: boolean;
  setShowMenu: (showNav: boolean) => void;
}> = ({ setShowMenu, showMenu }) => (
  <button
    aria-label="Toggle menu"
    onClick={() => setShowMenu(!showMenu)}
    className={cx(styles.navButton, { [styles.isOpen]: showMenu })}
  >
    {[1, 2, 3, 4].map(number => (
      <span key={number}></span>
    ))}
  </button>
);
