import Icon from '@components/atoms/Icon';
import Link from '@components/atoms/Link';
import Text from '@components/atoms/Text';
import Button from '@components/Button';
import {
  Dropdown,
  Hamburger,
  Logo
} from '@components/website/SiteHeader/NavComponents';
import { NAV_ANIMATION, NAVITEM_ANIMATION } from '@constants';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import styles from './NavNarrow.module.scss';

const NavNarrow: React.FC<{
  showMenu: boolean;
  setShowMenu: (showNav: boolean) => void;
}> = ({ setShowMenu, showMenu }) => {
  const [hasDropdown, setHasDropdown] = useState(false);
  const chevron = hasDropdown ? 'chevronUp' : 'chevronDown';

  return (
    <div className={cx(styles.wrapper, { [styles.isOpen]: showMenu })}>
      <div className={styles.navBar}>
        <Logo className={styles.logo} />
        <Hamburger setShowMenu={setShowMenu} showMenu={showMenu} />
      </div>

      <AnimatePresence>
        {showMenu && (
          <motion.nav
            initial="closed"
            animate="open"
            variants={NAV_ANIMATION}
            className={styles.nav}
            role="navigation"
          >
            <ul>
              <motion.li
                variants={NAVITEM_ANIMATION}
                className={styles.navItem}
              >
                <button
                  aria-haspopup="true"
                  onClick={() => setHasDropdown(!hasDropdown)}
                >
                  <Text as="span" variant="navLink">
                    Pio system
                  </Text>
                  <Icon name={chevron} size="xs" />
                </button>
                <Dropdown
                  isOpen={hasDropdown}
                  color="grey5"
                  className={styles.dropdown}
                />
              </motion.li>
              <motion.li
                variants={NAVITEM_ANIMATION}
                className={styles.navItem}
              >
                <Link href="/why-pio">
                  <Text as="span" variant="navLink">
                    Why Pio?
                  </Text>
                </Link>
              </motion.li>
              <motion.li
                variants={NAVITEM_ANIMATION}
                className={styles.navItem}
              >
                <Link href="/pricing">
                  <Text as="span" variant="navLink">
                    Pricing
                  </Text>
                </Link>
              </motion.li>
              <motion.li
                variants={NAVITEM_ANIMATION}
                className={styles.navItem}
              >
                <Link href="/contact">
                  <Text as="span" variant="navLink">
                    Contact
                  </Text>
                </Link>
              </motion.li>
              <li className={styles.fixedNavItem}>
                <Button variant="bigMainDark" href="/get-started">
                  Get started
                </Button>
              </li>
            </ul>
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NavNarrow;
