import Nav from '@components/website/SiteHeader/Nav';
import NavNarrow from '@components/website/SiteHeader/NavNarrow';
import { BREAKPOINT_STANDARD } from '@constants';
import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import styles from './SiteHeader.module.scss';

const SiteHeader: React.FC = () => {
  const [showNarrowMenu, setShowNarrowMenu] = useState(false);

  const isNarrow = useMedia(`(max-width: ${BREAKPOINT_STANDARD}px)`, false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true); // Fix isNarrow hydration issue
  }, []);

  return (
    <header className={styles.wrap}>
      {isClient &&
        (isNarrow ? (
          <NavNarrow
            showMenu={showNarrowMenu}
            setShowMenu={setShowNarrowMenu}
          />
        ) : (
          <Nav />
        ))}
    </header>
  );
};

export default SiteHeader;
